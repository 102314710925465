<template lang="pug">
div.bg-gray
  div.pr-5.thirstlevel(style="top:100px")
    v-row.flex-center
      v-col.pr-0
        bread-crumbs( :paramCOD="$route.params.paramCOD")
      v-col.col-filters.col-filters-big-home.pt-0.pb-0
        div.filters
          div.filter.filtroDate
            range-date

  div.mb-10
    div.margin-bottom-0.gridFull
      v-row
        v-col.titleGrid(md="12")
          span #[translate Número total de visitas]

      v-row
        .row.text-left.pb-3
          v-col.col-12(v-if="surveyStats")
            p.ml-4.mt-4.text-14 {{ surveyStats.name }}
              strong.text-16.pl-2 {{ surveyStats.total }}
          v-col.col-12.ml-4.py-1.text-12(v-if="surveyStatsAll && index!==0" v-for="(item,index) in surveyStatsAll" :key="item.id")
            span.mb-0(:class="{'link-text':index!==0}" @click="index!==0 ? goItemNextLevel(item) : null") {{ item.name }} 
              strong.pl-2 {{ item.total }}
          v-col.col-12(v-if="surveyStats")
            div.d-flex.justify-center.items-center.h-full
              div.d-flex.justify-center.gap-2em
                div.d-flex.flex-column.justify-end
                  div.d-flex.justify-center.items-center.mb-2
                    img(src="@/assets/img/promotoresCara.svg" :style="{ width: `calc(20px + ${surveyStats.google_redirect / 2}%)` }")
                  div.d-flex.flex-column.gap-0_25em.justify-center.items-center.px-10.py-2.border-top-2.border-top-gray
                    img(src="@/assets/img/promotoresPeople.svg")
                    div.text-14.text-semibold.color-positive Enviados a Google
                    div.d-flex.items-center.gap-0_25em
                      span.text-18.text-semibold {{ surveyStats.google_redirect }}
                      span.text-18(v-if="surveyStats.google_redirect && surveyStats.total") ({{ ((surveyStats.google_redirect * 100) / surveyStats.total) | formatNumber(2,2) }}%)   
</template>

<script>
import RangeDate from '@/components/filters/RangeDates.vue'
import BreadCrumbs from '@/components/common/Breadcrumbs.vue'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import chartGaugeTwoColors from '@/components/charts/GaugeTwoColors.vue'
import SrvRankingSatisfaction from '@/services/ranking-satisfaction.js'
import starRatingMini from '@/components/common/starRatingMiniFive.vue'

export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins],
  data() {
    return {
      data: undefined,
      surveyStats: null,
      surveyStatsAll: [],
      typePollNPSActive: 'total',
      typesPoll: [],
      keyQuestionNPS: 'rating_recommendation_question',
      hasSkyRocket: false
    }
  },
  components: { starRatingMini, RangeDate, BreadCrumbs, chartGaugeTwoColors },
  async created() {
    this.$store.commit(types.UPDATE_SECTION, 3)

    const resStats = await SrvRankingSatisfaction.getSummarySatisfactionStatsSurvey()
    if (resStats.status === 200) {
      this.surveyStatsAll = resStats.data
      this.surveyStats = resStats.data[0]
    }
    this.loadUrlParams()
  },
  methods: {
    getFieldForNPS(field = 'total') {
      return this.typePollNPSActive === 'total' ? this.data[0].nps[field] : this.data[0].nps['nps_by_type'][this.typePollNPSActive.toLowerCase()][field]
    },
    getFieldForNPSTend(field = 'total') {
      return this.typePollNPSActive === 'total' ? this.data[0].nps_tend[field] : this.data[0].nps_tend['nps_by_type'][this.typePollNPSActive.toLowerCase()][field]
    },
    calcDiff(c, l) {
      const diff = c - l
      const result = diff * 100 / l
      return isNaN(result) || !isFinite(result) ? 0 : result
    }
  }
}
</script>

<style lang="scss" scoped>
.buttonTypePoll {
  border: 1px solid #CECECE;
  color: #222226;
  font-size: 0.75rem;
  padding: 8px 20px;

  &.active {
    background-color: #E0E0E0;
  }
}

.borderCorporate {
  border-right: solid 2px #9797973d;
}

.head {
  padding-bottom: 2rem;
}

.tsm-table-ranking {
  width: 100%;
  margin-left: 1px;
  border-collapse: collapse;

  thead {
    font-weight: normal;
    font-size: 12px;

    td {
      padding: 0.5rem 0.5rem 0.25rem;
      border-bottom: solid 2px #9797973d;
      width: auto;

      div {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }

  tbody {
    font-weight: normal;
    font-size: 14px;

    tr:last-child {
      td {
        padding: 0.5rem 0.5rem 0.7rem;
        border-bottom: solid 1px transparent;
      }
    }

    td {
      padding: 0.5rem;
      border-bottom: solid 1px #9797973d;

      div {
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

.tsm-table-ranking thead .table-title {
  border: none;
}

.text-red {
  color: #FF0001 !important;
}

.text-orange {
  color: #E2C536 !important;
}

.text-green {
  color: #66C744 !important;
}
</style>
